import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  Alert,
  Modal,
  FlatList,
  ScrollView,
  Linking,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { firestore, storage, auth } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';

const adTypes = ['Banner Ad', 'Promote Profile'];
const durations = ['24 hours', '1 week', '2 weeks', '3 weeks', '4 weeks'];
const bannerImageOptions = ['Upload your own banner image', 'Have our team create a banner image'];

const RequestAdScreen: React.FC = () => {
  const navigation = useNavigation();
  const [adType, setAdType] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [isAdTypeModalVisible, setAdTypeModalVisible] = useState(false);
  const [isDurationModalVisible, setDurationModalVisible] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isBannerImageModalVisible, setBannerImageModalVisible] = useState(false);
  const [bannerImageOption, setBannerImageOption] = useState('');
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleImagePicker = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images, // Correct enum value
        allowsEditing: true,
        quality: 0.5,
      });
  
      if (!result.canceled && result.assets && result.assets[0].uri) {
        setUploading(true);
        const imageUri = result.assets[0].uri;
  
        const response = await fetch(imageUri);
        const blob = await response.blob();
  
        const imageRef = ref(storage, `bannerImages/${auth.currentUser?.uid}/${Date.now()}-banner.jpg`);
        await uploadBytes(imageRef, blob);
        const downloadUrl = await getDownloadURL(imageRef);
        setBannerImage(downloadUrl);
  
        Alert.alert('Success', 'Image uploaded successfully.');
      } else {
        Alert.alert('Error', 'Image selection was canceled.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      Alert.alert('Error', 'Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };
  

  const handleSubmit = async () => {
    if (!adType || !email || !startDate || !duration || !description || (adType === 'Banner Ad' && !bannerImageOption)) {
      Alert.alert('Error', 'Please fill out all fields.');
      return;
    }

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        Alert.alert('Error', 'User not authenticated. Please log in again.');
        return;
      }

      const vendorId = currentUser.uid;
      const vendorName = currentUser.displayName || 'Unknown Vendor';

      await addDoc(collection(firestore, 'adRequests'), {
        adType,
        email,
        startDate: startDate.toISOString(),
        duration,
        description,
        bannerImageOption,
        bannerImage: bannerImageOption === 'Upload your own banner image' ? bannerImage : null,
        requestedAt: new Date().toISOString(),
        vendorId,
        vendorName,
      });

      Alert.alert('Success', 'Your ad request has been submitted!');
      navigation.goBack();
    } catch (error) {
      Alert.alert('Error', 'Failed to submit the ad request. Try again.');
      console.error('Error adding ad request:', error);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
        <SafeAreaView>
          <View style={styles.header}>
            <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
              <Ionicons name="arrow-back" size={24} color="#333" />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Request an Ad or Promotion</Text>
          </View>
        </SafeAreaView>

        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.keyboardView}>
          <ScrollView contentContainerStyle={styles.scrollContainer} keyboardShouldPersistTaps="handled">
            <View style={styles.inputGroup}>
              <Text style={styles.label}>Ad Type</Text>
              <TouchableOpacity style={[styles.input, styles.categoryInput]} onPress={() => setAdTypeModalVisible(true)}>
                <Text style={styles.dropdownButtonText}>
                  {adType ? adType : 'Select Ad Type'}
                </Text>
                <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
              </TouchableOpacity>
            </View>

            <View style={styles.inputGroup}>
  <Text style={styles.label}>Start Date</Text>

  {/* Mobile Date Picker */}
  {Platform.OS !== 'web' && (
    <>
      <TouchableOpacity style={[styles.input, styles.categoryInput]} onPress={() => setShowDatePicker(true)}>
        <Text style={styles.dropdownButtonText}>
          {startDate ? startDate.toDateString() : 'Select Start Date'}
        </Text>
        <Ionicons name="calendar" size={22} color="#333" style={styles.iconSpacing} />
      </TouchableOpacity>
      {showDatePicker && (
        <DateTimePicker
          value={startDate || new Date()}
          mode="date"
          display="default"
          onChange={(event, date) => {
            setShowDatePicker(false);
            if (date) setStartDate(date);
          }}
        />
      )}
    </>
  )}

  {/* Web Date Picker */}
  {Platform.OS === 'web' && (
    <input
      type="date"
      value={startDate?.toISOString().substring(0, 10) || ''}
      onChange={(e) => setStartDate(new Date(e.target.value))}
      style={{
        height: 50,
        width: '100%',
        padding: 10,
        borderColor: '#b6cc9b',
        borderWidth: 2,
        borderRadius: 15,
        fontSize: 14,
        marginTop: 5,
      }}
    />
  )}
</View>


            <View style={styles.inputGroup}>
              <Text style={styles.label}>Duration</Text>
              <TouchableOpacity style={[styles.input, styles.categoryInput]} onPress={() => setDurationModalVisible(true)}>
                <Text style={styles.dropdownButtonText}>
                  {duration ? duration : 'Select Duration'}
                </Text>
                <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
              </TouchableOpacity>
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Your Email</Text>
              <TextInput
                style={styles.input}
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
              />
            </View>

            <View style={styles.inputGroup}>
              <Text style={styles.label}>Company details for invoicing</Text>
              <TextInput
                style={[styles.input, styles.textArea]}
                value={description}
                onChangeText={setDescription}
                multiline
              />
            </View>

            {adType === 'Banner Ad' && (
              <View style={styles.inputGroup}>
                <Text style={styles.label}>Banner Image</Text>
                <TouchableOpacity style={[styles.input, styles.categoryInput]} onPress={() => setBannerImageModalVisible(true)}>
                  <Text style={styles.dropdownButtonText}>
                    {bannerImageOption ? bannerImageOption : 'Upload or Request a Banner'}
                  </Text>
                  <Ionicons name="chevron-down" size={22} color="#333" style={styles.iconSpacing} />
                </TouchableOpacity>

                {bannerImageOption === 'Upload your own banner image' && (
                  <TouchableOpacity style={styles.imageUploadButton} onPress={handleImagePicker}>
                    <Text style={styles.uploadText}>
                      {bannerImage ? 'Image Uploaded' : 'Choose Image'}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            )}

            {uploading && <ActivityIndicator size="large" color="#b6cc9b" />}

            <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
              <Text style={styles.submitButtonText}>Submit Request</Text>
            </TouchableOpacity>
            <TouchableOpacity
  style={styles.pdfButton}
  onPress={() => {
    Linking.openURL('https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Faweyy%20ad%20pricelist.pdf?alt=media&token=8b9f4061-3c2e-4a97-b590-861794ceffcc');
  }}
>
  <Text style={styles.pdfButtonText}>View Ad Price List</Text>
</TouchableOpacity>


          </ScrollView>
        </KeyboardAvoidingView>
        

        {/* Ad Type Modal */}
        <Modal transparent={true} visible={isAdTypeModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Ad Type</Text>
              <FlatList
                data={adTypes}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setAdType(item);
                      setAdTypeModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setAdTypeModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Duration Modal */}
        <Modal transparent={true} visible={isDurationModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Select Duration</Text>
              <FlatList
                data={durations}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setDuration(item);
                      setDurationModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setDurationModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Banner Image Modal */}
        <Modal transparent={true} visible={isBannerImageModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Banner Image Option</Text>
              <FlatList
                data={bannerImageOptions}
                keyExtractor={(item) => item}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={styles.categoryCard}
                    onPress={() => {
                      setBannerImageOption(item);
                      setBannerImageModalVisible(false);
                    }}
                  >
                    <Text style={styles.categoryText}>{item}</Text>
                  </TouchableOpacity>
                )}
              />
              <TouchableOpacity onPress={() => setBannerImageModalVisible(false)} style={styles.closeModalButton}>
                <Text style={styles.closeModalText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  background: { flex: 1 },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginBottom: 25,
  },
  backButton: { padding: 5 },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  keyboardView: { flex: 1 },
  scrollContainer: { alignItems: 'center', paddingBottom: 20 },
  inputGroup: {
    marginBottom: 15,
    width: '90%',
  },
  label: {
    fontSize: 14,
    fontWeight: '600',
    color: '#333',
    marginBottom: 5,
  },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#b6cc9b',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    fontSize: 14,
    color: '#333',
  },
  categoryInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  dropdownButtonText: {
    fontSize: 14,
    color: '#a4a4a4',
    fontWeight: '500',
  },
  iconSpacing: {
    marginLeft: 5,
  },
  submitButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    marginTop: 20,
    width: 200,
    alignItems: 'center',
  },
  submitButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    marginHorizontal: 20,
    borderRadius: 15,
    padding: 20,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
  },
  categoryCard: {
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 250,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#b6cc9b',
  },
  categoryText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  closeModalButton: {
    marginTop: 20,
    backgroundColor: '#b6cc9b',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 15,
  },
  closeModalText: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },
  imageUploadButton: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#b6cc9b',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 15,
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadText: {
    fontSize: 14,
    color: '#a4a4a4',
    fontWeight: '500',
  },
  pdfButton: {
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#b6cc9b',
    marginTop: 20,
    alignItems: 'center',
  },
  pdfButtonText: {
    fontSize: 16,
    color: '#b6cc9b',
    fontWeight: '600',
  },
  
});

export default RequestAdScreen;
