import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, StyleSheet, TouchableOpacity, ActivityIndicator, Image, Modal, Alert, Platform } from 'react-native';
import { auth, firestore } from '../../firebaseConfig'; // auth is now properly typed
import { User } from 'firebase/auth';
import { getDoc, doc, query, where, getDocs, updateDoc, arrayRemove, collection } from 'firebase/firestore';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import VendorProfileScreen from './VendorProfileScreen';

export default function SharedListScreen() {
  const [sharedVendors, setSharedVendors] = useState<{ [key: string]: any[] }>({});
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null); 
  const currentUser: User | null = auth.currentUser;
  const navigation = useNavigation();

  useEffect(() => {
    const fetchSharedVendors = async () => {
      if (!currentUser) return;
  
      try {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userDocRef);
  
        if (!userSnap.exists()) {
          console.error('User document not found');
          return;
        }
  
        const userData = userSnap.data();
        const sharedList = userData.sharedList || [];
        const vendorsByUser: { [key: string]: any[] } = {};
  
        await Promise.all(
          sharedList.map(async (sharedItem: any) => {
            const { vendorId, sharedBy, sharedWith, location } = sharedItem;
  
            if (!vendorId || (!sharedBy && !sharedWith)) {
              console.warn('Skipping incomplete sharedItem:', sharedItem);
              return;
            }
  
            const otherUserEmail = sharedBy === currentUser.email
              ? sharedWith || null
              : sharedBy || null;
  
            if (!otherUserEmail) {
              console.warn('Missing otherUserEmail in sharedItem:', sharedItem);
              return;
            }
  
            const vendorDocRef = doc(firestore, 'vendors', vendorId);
            const vendorSnap = await getDoc(vendorDocRef);
  
            if (!vendorSnap.exists()) {
              console.error('Vendor not found for ID:', vendorId);
              return;
            }
  
            const vendor = vendorSnap.data();
  
            // Fetch the profile of the "other user"
            const otherUserQuery = query(
              collection(firestore, 'users'),
              where('email', '==', otherUserEmail)
            );
            const otherUserSnap = await getDocs(otherUserQuery);
  
            let otherUserData = {
              name: otherUserEmail,
              profileImage: 'https://via.placeholder.com/50',
            };
            
            if (!otherUserSnap.empty) {
              const otherUser = otherUserSnap.docs[0].data();
              otherUserData = {
                name: otherUser.name || otherUserEmail,
                profileImage: otherUser.profileImage || 'https://via.placeholder.com/50',
              };
            }
            
  
            // Group vendors by the other user's email
            if (!vendorsByUser[otherUserEmail]) {
              vendorsByUser[otherUserEmail] = [];
            }
  
            vendorsByUser[otherUserEmail].push({
              ...vendor,
              location: location || 'Unknown location',
              otherUser: otherUserData,
              vendorId,           // if you still need this field
              userId: vendorId,   // ensure the vendor object has a userId property
            });
            
          })
        );
  
        setSharedVendors(vendorsByUser);
      } catch (error) {
        console.error('Error fetching shared vendors:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchSharedVendors();
  }, [currentUser]);
  

  

  const handleVendorPress = (vendor: any) => {
    setSelectedVendor(vendor);
    setModalVisible(true);
  };

  const handleUserPress = (userEmail: string) => {
    setSelectedUser(selectedUser === userEmail ? null : userEmail);
  };

  const handleLocationPress = (location: string) => {
    setSelectedLocation(selectedLocation === location ? null : location);
  };

  // Handle platform-specific confirmation dialogs
  const confirmDelete = (item: string, callback: () => void) => {
    if (Platform.OS === 'web') {
      if (window.confirm(`Are you sure you want to delete ${item}?`)) {
        callback();
      }
    } else {
      Alert.alert(
        'Confirm Delete',
        `Are you sure you want to delete ${item}?`,
        [
          { text: 'Cancel', style: 'cancel' },
          { text: 'Delete', style: 'destructive', onPress: callback },
        ],
        { cancelable: true }
      );
    }
  };

  const handleRemoveVendor = async (vendor: any) => {
    if (!currentUser || !vendor.vendorId || !vendor.location) return;
  
    confirmDelete(vendor.vendorName, async () => {
      try {
        
        const currentUserDocRef = doc(firestore, 'users', currentUser.uid);
  
        // Fetch the latest sharedList from Firestore
        const currentUserDocSnap = await getDoc(currentUserDocRef);
        const currentSharedList = currentUserDocSnap.data()?.sharedList || [];
  
        // Find the exact object to remove in the current user's sharedList
        const objectToRemove = currentSharedList.find(
          (item: any) =>
            item.vendorId === vendor.vendorId &&
            item.location === vendor.location &&
            (item.sharedBy === currentUser.email || item.sharedWith === currentUser.email)
        );
  
        if (!objectToRemove) {
          console.warn('Object to remove not found in sharedList');
          return;
        }
  
        // Use arrayRemove to delete the exact object
        await updateDoc(currentUserDocRef, {
          sharedList: arrayRemove(objectToRemove),
        });
  
        // Update local state to reflect the deletion
        setSharedVendors((prev) => {
          const updatedVendors = { ...prev };
          updatedVendors[selectedUser] = updatedVendors[selectedUser]?.filter(
            (v) => v.vendorId !== vendor.vendorId
          );
          return updatedVendors;
        });
      } catch (error) {
        console.error('Error removing vendor for currentUser:', error);
        Alert.alert('Error', error.message || 'Failed to remove vendor.');
      }
    });
  };
  
  
  
  

  const handleRemoveUser = async (sharedByEmail: string) => {
    confirmDelete('this user and all shared vendors', async () => {
      if (!currentUser || !sharedByEmail) return;
  
      try {
  
        const currentUserDocRef = doc(firestore, 'users', currentUser.uid);
  
        // Fetch the latest sharedList from Firestore
        const currentUserDocSnap = await getDoc(currentUserDocRef);
        const currentSharedList = currentUserDocSnap.data()?.sharedList || [];
  
        // Get all vendors shared with/by the user
        const sharedUserVendors = sharedVendors[sharedByEmail] || [];
  
        for (const vendor of sharedUserVendors) {
          const { vendorId, location } = vendor;
  
          if (!vendorId || !location) {
            console.warn('Skipping incomplete vendor data:', vendor);
            continue; // Skip if any required field is missing
          }

  
          // Remove the object from the current user's sharedList
          const objectToRemove = currentSharedList.find(
            (item: any) =>
              item.vendorId === vendorId &&
              item.location === location &&
              (item.sharedBy === sharedByEmail || item.sharedWith === sharedByEmail)
          );
  
          if (!objectToRemove) {
            console.warn('Object to remove not found in sharedList:', {
              vendorId,
              location,
              sharedByEmail,
            });
            continue;
          }
  
          await updateDoc(currentUserDocRef, {
            sharedList: arrayRemove(objectToRemove),
          });
        }
  
        // Remove the user from the local sharedVendors state
        setSharedVendors((prev) => {
          const updatedVendors = { ...prev };
          delete updatedVendors[sharedByEmail];
          return updatedVendors;
        });
  
        setSelectedUser(null);
        Alert.alert('Success', `Removed all vendors shared with ${sharedByEmail}.`);
      } catch (error) {
        console.error('Error removing user from shared list:', error);
        Alert.alert('Error', error.message || 'Failed to remove user and their vendors.');
      }
    });
  };
  

  const goBackOrClose = () => {
    navigation.goBack();
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color="#b6cc9b" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.topCloseButton} onPress={goBackOrClose}>
        <Ionicons name="arrow-back" size={30} color="#4A4A4A" />
      </TouchableOpacity>
  
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {Object.keys(sharedVendors).length === 0 ? (
          <Text style={styles.noDataText}>
            No vendors shared with you yet. Try sharing a vendor with a friend, and it will appear here.
          </Text>
        ) : (
          Object.keys(sharedVendors).map((userEmail, index) => (
            <View key={index}>
              <View style={styles.sharedUserRow}>
                {/* Use `sharedByProfileImage` and `sharedByName` */}
                <Image
                  source={{
                    uri: sharedVendors[userEmail][0]?.otherUser?.profileImage || 'https://via.placeholder.com/50',
                  }}
                  style={styles.sharedUserImage}
                />
                <TouchableOpacity
                  style={styles.sharedUserButton}
                  onPress={() => handleUserPress(userEmail)}
                >
                  <Text style={styles.sharedUserText}>
                    Your shared list with {sharedVendors[userEmail][0]?.otherUser?.name || userEmail}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleRemoveUser(userEmail)}>
                  <Ionicons name="trash-outline" size={24} color="black" style={styles.removeIcon} />
                </TouchableOpacity>
              </View>
  
              {selectedUser === userEmail &&
                [...new Set(sharedVendors[userEmail].map((v) => v.location))].map(
                  (location, locIndex) => (
                    <TouchableOpacity
                      key={locIndex}
                      style={styles.locationButton}
                      onPress={() => handleLocationPress(location)}
                    >
                      <Text style={styles.locationText}>Location: {location}</Text>
                    </TouchableOpacity>
                  )
                )}
  
              {selectedUser === userEmail &&
                selectedLocation &&
                sharedVendors[userEmail]
                  .filter((v) => v.location === selectedLocation)
                  .map((vendor, vendorIndex) => (
                    <View style={styles.vendorRow} key={vendorIndex}>
                      <TouchableOpacity
                        style={styles.vendorCard}
                        onPress={() => handleVendorPress(vendor)}
                      >
                        <Image
                          source={{ uri: vendor.profilePicture }}
                          style={styles.vendorImage}
                          resizeMode="cover"
                        />
                        <View style={styles.vendorInfo}>
                          <Text style={styles.vendorName}>{vendor.vendorName}</Text>
                          <Text style={styles.vendorLocation}>
                            <Ionicons name="location-outline" size={16} color="#4A4A4A" />
                            {vendor.location}
                          </Text>
                          <Text style={styles.vendorCategory}>{vendor.category}</Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => handleRemoveVendor(vendor, userEmail)}>
                        <Ionicons name="trash-outline" size={24} color="black" style={styles.removeIcon} />
                      </TouchableOpacity>
                    </View>
                  ))}
            </View>
          ))
        )}
      </ScrollView>
  
      {selectedVendor && (
        <Modal
          visible={modalVisible}
          animationType="slide"
          onRequestClose={() => setModalVisible(false)}
        >
          <VendorProfileScreen
            vendor={selectedVendor}
            isExplorer={true}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      )}
    </View>
  );
  
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollContainer: {
    backgroundColor: '#fff',
    paddingBottom: 100,
    flexGrow: 1,
    paddingHorizontal: 20,
    paddingTop: 120,
  },
  topCloseButton: {
    position: 'absolute',
    top: 40,
    left: 20,
    zIndex: 10,
  },
  title: {
    fontSize: 28,
    fontWeight: '700',
    color: '#4A4A4A',
    textAlign: 'center',
    marginBottom: 20,
  },
  noDataText: {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
    marginTop: 20,
  },
  sharedUserRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sharedUserImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  sharedUserButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 15,
    alignItems: 'center',
    marginVertical: 10,
    flex: 1,
  },
  sharedUserText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#4A4A4A',
  },
  locationButton: {
    backgroundColor: '#f0f0f0',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginVertical: 5,
    marginLeft: 30,
  },
  locationText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#4A4A4A',
  },
  vendorRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  vendorCard: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
    flex: 1,
  },
  vendorImage: {
    width: 80,
    height: 80,
    borderRadius: 10,
  },
  vendorInfo: {
    flex: 1,
    marginLeft: 15,
    justifyContent: 'center',
  },
  vendorName: {
    fontSize: 18,
    fontWeight: '700',
    color: '#4A4A4A',
  },
  vendorLocation: {
    fontSize: 14,
    color: '#4A4A4A',
    marginTop: 5,
  },
  vendorCategory: {
    fontSize: 16,
    color: '#93C572',
    marginTop: 5,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  removeIcon: {
    marginLeft: 10,
  },
});
