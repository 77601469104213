import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export default function TermsOfServiceScreen() {
  const navigation = useNavigation();

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Terms of Service</Text>
        </View>

        {/* Content */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          <Text style={styles.sectionTitle}>Effective Date: 1 February 2025</Text>

          <Text style={styles.content}>
            Welcome to AWEYY! These Terms of Service ("Terms") govern your access to and use of the AWEYY app and services. 
            By using our services, you agree to these Terms. If you do not agree, you must not use AWEYY.
          </Text>

          {/* Introduction */}
          <Text style={styles.subTitle}>1. Introduction</Text>
          <Text style={styles.content}>
            AWEYY helps users discover vendors, save favorites, and share experiences. By using AWEYY, you agree to:
            {'\n'}- Comply with these Terms and applicable laws.
            {'\n'}- Provide accurate information during account creation.
            {'\n'}- Refrain from unauthorized access or harmful activities.
          </Text>

          {/* Eligibility */}
          <Text style={styles.subTitle}>2. Eligibility</Text>
          <Text style={styles.content}>
            2.1. You must be at least 13 years old to use AWEYY. Users under 18 require parental consent.
            {'\n'}2.2. AWEYY accounts disabled for violations cannot be recreated without written consent.
          </Text>

          {/* User Responsibilities */}
          <Text style={styles.subTitle}>3. User Responsibilities</Text>
          <Text style={styles.content}>
            3.1. Account Security: You are responsible for maintaining account confidentiality. AWEYY is not liable for unauthorized access.
            {'\n'}3.2. Prohibited Activities: You may not use AWEYY to post illegal, harmful, or infringing content.
            {'\n'}3.3. User Conduct: You agree to use the platform responsibly and not engage in harassment, fraud, or abuse.
          </Text>

          {/* Content Ownership */}
          <Text style={styles.subTitle}>4. Content and Intellectual Property</Text>
          <Text style={styles.content}>
            4.1. User Content: Content you submit to AWEYY (reviews, images, etc.) remains yours, but you grant us a license to use it for service functionality and promotional purposes.
            {'\n'}4.2. Intellectual Property: AWEYY’s name, branding, and assets are protected by copyright and trademark laws.
          </Text>

          {/* Payment & Subscriptions */}
          <Text style={styles.subTitle}>5. Payment, Subscriptions, and Refunds</Text>
          <Text style={styles.content}>
            5.1. Paid Promotions: Vendors can purchase advertisement banners and profile promotions within the app.
            {'\n'}5.2. Subscription-Based Features: Some premium features may require in-app purchases or subscriptions.
            {'\n'}5.3. Refund Policy: Payments for ads and promotions are non-refundable unless due to a technical error. Refunds are processed at AWEYY’s discretion.
          </Text>

          {/* Advertising Policy */}
          <Text style={styles.subTitle}>6. Advertising Policy</Text>
          <Text style={styles.content}>
            6.1. Ads must comply with our content guidelines and applicable advertising laws.
            {'\n'}6.2. AWEYY reserves the right to reject or remove advertisements that violate policies.
            {'\n'}6.3. Sponsored content will be clearly labeled as "Sponsored", "Ad" or "Promoted".
          </Text>

          {/* Privacy */}
          <Text style={styles.subTitle}>7. Privacy</Text>
          <Text style={styles.content}>
            7.1. Your use of AWEYY is governed by our Privacy Policy, which outlines data collection and usage practices.
          </Text>

          {/* Vendor Interactions */}
          <Text style={styles.subTitle}>8. Vendor Interactions & Liability</Text>
          <Text style={styles.content}>
            8.1. Vendors are independent businesses and are responsible for the accuracy of their listings.
            {'\n'}8.2. AWEYY is not responsible for any transactions or agreements between users and vendors.
          </Text>

          {/* Third-Party Services */}
          <Text style={styles.subTitle}>9. Third-Party Services</Text>
          <Text style={styles.content}>
            9.1. AWEYY may integrate with third-party services such as Google Analytics, Firebase, and payment processors.
            {'\n'}9.2. Users must review third-party privacy policies before engaging with these services.
          </Text>

          {/* Dispute Resolution */}
          <Text style={styles.subTitle}>10. Dispute Resolution & Governing Law</Text>
          <Text style={styles.content}>
            10.1. Disputes must be settled through arbitration in accordance with applicable law.
            {'\n'}10.2. These Terms are governed by and construed in accordance with the laws of the State of Delaware, United States, without regard to conflict of law principles.
          </Text>

          {/* Compliance with Apple App Store */}
          <Text style={styles.subTitle}>11. Compliance with App Store Guidelines</Text>
          <Text style={styles.content}>
            11.1. These Terms comply with Apple’s App Store Guidelines.
            {'\n'}11.2. Apple is not responsible for the content or operation of AWEYY.
            {'\n'}11.3. Any in-app purchases made through the App Store are subject to Apple’s payment policies.
          </Text>

          {/* Contact Us */}
          <Text style={styles.subTitle}>12. Contact Us</Text>
          <Text style={styles.content}>
            If you have questions or concerns about these Terms, contact us at:{'\n'}
            Email: heyy@aweyy.com{'\n'}
          </Text>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  contentContainer: {
    padding: 20,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#555',
    marginTop: 20,
    marginBottom: 10,
  },
  content: {
    fontSize: 14,
    lineHeight: 22,
    color: '#666',
    textAlign: 'justify',
  },
});
