import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Switch,
  TouchableOpacity,
  SafeAreaView,
  Alert,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';

export default function PrivacySettingsScreen() {
  const navigation = useNavigation();

  // State for toggles
  const [allowAnalytics, setAllowAnalytics] = useState(true);
  const [allowNotifications, setAllowNotifications] = useState(true);

  // Save settings (can be expanded to sync with backend)
  const handleSaveSettings = () => {
    Alert.alert('Success', 'Privacy settings updated!');
    // Save preferences like allowAnalytics and allowNotifications to the backend here if necessary
  };

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Privacy Settings</Text>
        </View>

        {/* Settings */}
        <View style={styles.form}>
          {/* Analytics toggle */}
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Allow Usage Analytics</Text>
            <Switch
              value={allowAnalytics}
              onValueChange={setAllowAnalytics}
              trackColor={{ false: '#ddd', true: '#fff' }}
              thumbColor={allowAnalytics ? '#b6cc9b' : '#f4f3f4'}
            />
          </View>

          {/* Notifications toggle */}
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Allow Notifications</Text>
            <Switch
              value={allowNotifications}
              onValueChange={setAllowNotifications}
              trackColor={{ false: '#ddd', true: '#fff' }}
              thumbColor={allowNotifications ? '#b6cc9b' : '#f4f3f4'}
            />
          </View>

          {/* Save button */}
          <TouchableOpacity style={styles.saveButton} onPress={handleSaveSettings}>
            <Text style={styles.saveButtonText}>Save Settings</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  form: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 30,
  },
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  label: {
    fontSize: 14,
    fontWeight: '600',
    color: '#333',
  },
  saveButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  saveButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
});
