import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
  ScrollView,
  FlatList,
  Dimensions,
  ActivityIndicator,
  Modal,
  Platform,
  Image,
  Linking,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { firestore, auth } from '../../firebaseConfig';
import { getDoc, collection, getDocs, updateDoc, doc, query, where, increment } from 'firebase/firestore';
import VendorCard from './VendorCard';
import VendorProfileScreen from './VendorProfileScreen';

// Fix: Adjust ITEM_WIDTH dynamically for responsiveness
const ITEM_WIDTH = Platform.OS === 'web'
  ? '24%'
  : Math.min(Dimensions.get('window').width * 0.9, 140);

type HomePageProps = {
  navigation: StackNavigationProp<any>;
  route: RouteProp<{ Home: { location: string; categories: string[] } }, 'Home'>;
};

type Vendor = {
  vendorName: string;
  location: string;
  category: string;
  userId: string;
  promoted?: boolean;
  promotionExpiration?: number | null;
  // favoriteCount is used to sort "most popular" vendors
  favoriteCount?: number;
};

type Ad = {
  id: string;
  imageUrl: string;
  vendorId?: string;
  websiteUrl?: string;
  expired?: string;
  screenLink?: "Recommendations";
};

export default function HomePage({ navigation }: HomePageProps) {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [ads, setAds] = useState<Ad[]>([]);
  const [vendorData, setVendorData] = useState<{ [key: string]: Vendor[] }>({});
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Fetch user preferences (location, categories)
  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setLocation(userData.location);
          setCategories(userData.categories);
        } else {
          setErrorMessage('User data not found');
        }
      } catch (error) {
        setErrorMessage('Error fetching user data');
      }
    }
  };

  // Fetch ads once at startup
  const fetchAds = async () => {
    try {
      const adsCollection = collection(firestore, 'ads');
      
      let vendorAds: Ad[] = [];
      if (location && categories.length > 0) {
        const vendorAdsQuery = query(
          adsCollection,
          where('expired', '==', false),
          where('location', '==', location),
          where('category', 'in', categories)
        );
        const vendorAdsSnapshot = await getDocs(vendorAdsQuery);
        vendorAds = vendorAdsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Ad[];
      }
      
      const screenAdsQuery = query(
        adsCollection,
        where('expired', '==', false),
        where('screenLink', '==', 'recommendations')
      );
      const screenAdsSnapshot = await getDocs(screenAdsQuery);
      const screenAds = screenAdsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Ad[];
      
      const mergedAds = [...vendorAds, ...screenAds];
      const adsMap = new Map<string, Ad>();
      mergedAds.forEach((ad) => adsMap.set(ad.id, ad));
      const finalAds = Array.from(adsMap.values());
      
      // Option 1: For stable order, remove sorting:
      setAds(finalAds);
      
      // Option 2: For randomized order once, uncomment the following line:
      // setAds(finalAds.sort(() => Math.random() - 0.5));
    } catch (error) {
      console.error('Error fetching ads:', error);
      setErrorMessage('Error fetching ads');
    }
  };
  

  useEffect(() => {
    if (location && categories.length > 0) {
      fetchAds();
    }
  }, [location, categories]);
  

  // Fetch vendors, group them by category, then sort within each category by favoriteCount
  const fetchVendors = async () => {
    if (!location || categories.length === 0) {
      setLoading(false);
      return;
    }

    try {
      const vendorCollection = collection(firestore, 'vendors');
      const vendorQuery = query(vendorCollection, where('location', '==', location));
      const vendorQuerySnapshot = await getDocs(vendorQuery);

      const vendorsByCategory: { [key: string]: Vendor[] } = {};

      vendorQuerySnapshot.docs.forEach((vendorDoc) => {
        const vendor = vendorDoc.data() as Vendor;
        if (categories.includes(vendor.category)) {
          if (!vendorsByCategory[vendor.category]) {
            vendorsByCategory[vendor.category] = [];
          }
          // Optionally, you could also check the promoted flag here if needed.
          vendorsByCategory[vendor.category].push({ ...vendor, userId: vendorDoc.id });
        }
      });

      for (const category in vendorsByCategory) {
        vendorsByCategory[category].sort((a, b) => {
          // If one vendor is promoted and the other is not, the promoted vendor comes first.
          if (a.promoted && !b.promoted) return -1;
          if (!a.promoted && b.promoted) return 1;
      
          // Otherwise, sort by favoriteCount (highest first), defaulting to 0 if undefined.
          return (b.favoriteCount || 0) - (a.favoriteCount || 0);
        });
      }
      

      setVendorData(vendorsByCategory);
      setErrorMessage(null);
      setLoading(false);
    } catch (error) {
      setErrorMessage('Error fetching vendor data');
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fire off both requests in parallel once at startup
    fetchUserData();
    // fetchAds() is already run in its own useEffect above
  }, []);

  useEffect(() => {
    if (location && categories.length > 0) {
      fetchVendors();
    }
  }, [location, categories]);

  useFocusEffect(
    React.useCallback(() => {
      // When the HomePage comes into focus, re-fetch the user data and vendors.
      fetchUserData();
      if (location && categories.length > 0) {
        fetchVendors();
      }
    }, [location, categories])
  );
  

  // Handler for ad presses remains unchanged
  const handleAdPress = async (ad: Ad) => {
  if (ad.id) {
    const adRef = doc(firestore, 'ads', ad.id);
    try {
      await updateDoc(adRef, {
        clickCount: increment(1), // More efficient way to update clicks
      });
    } catch (error) {
      console.error("Error updating click count:", error);
    }
  }

  if (ad.vendorId) {
    const vendor = Object.values(vendorData).flat().find((v) => v.userId === ad.vendorId);
    if (vendor) {
      setSelectedVendor(vendor);
      setModalVisible(true);
      return;
    }
  }

  if (ad.websiteUrl) {
    const url = ad.websiteUrl.startsWith('http') ? ad.websiteUrl : `https://${ad.websiteUrl}`;
    Linking.openURL(url).catch((err) => console.error('Failed to open URL', err));
    return;
  }

  if (ad.screenLink?.toLowerCase() === "recommendations") {
    navigation.navigate("Recommendations");
    return;
  }
};

  const renderAdCarousel = () => {
    const windowWidth = Dimensions.get('window').width;
    const isWeb = Platform.OS === 'web';
    const isLargeScreen = windowWidth > 600; // Large screens for better grid layout

    return (
      <View style={styles.adCarouselContainer}>
        <FlatList
          data={ads}
          keyExtractor={(item) => item.id}
          horizontal={!isWeb || !isLargeScreen} // Horizontal for mobile web, grid for desktop
          numColumns={isWeb && isLargeScreen ? 3 : 1}
          key={isWeb && isLargeScreen ? 'grid' : 'list'} // Force re-render when layout changes
          columnWrapperStyle={isWeb && isLargeScreen ? styles.adColumnWrapper : undefined}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={
            isWeb && isLargeScreen ? styles.adGridContainer : styles.adHorizontalContainer
          }
          renderItem={({ item }) => (
            <TouchableOpacity
              style={isWeb && isLargeScreen ? styles.adGridCard : styles.adTouchableCard}
              onPress={() => handleAdPress(item)}
            >
              <View style={styles.adImageContainer}>
                <Image
                  source={{ uri: item.imageUrl }}
                  style={styles.adImage}
                  resizeMode="cover"
                />
              </View>
            </TouchableOpacity>
          )}
        />
      </View>
    );
  };

  const renderVendorCarousel = (category: string) => {
    const isLargeScreen = Dimensions.get('window').width > 600;
    const maxVendors = isLargeScreen ? 4 : 6;
    const categoryVendors = (vendorData[category] || []).slice(0, maxVendors);

    if (categoryVendors.length === 0) {
      return (
        <View key={category} style={styles.noVendorsContainer}>
          <Text style={styles.noVendorsText}>
            No vendors available in {category} right now. If you have some places in mind, we would love to hear your recommendations.
          </Text>
        </View>
      );
    }

    return (
      <View key={category} style={styles.vendorSection}>
        <View style={styles.vendorHeader}>
          <Text style={styles.vendorTitle}>
            Most Popular {category} in {location}
          </Text>
          <TouchableOpacity
            onPress={() => navigation.navigate('All Vendors', { category, location })}
          >
            <Text style={styles.seeAllText}>See All</Text>
          </TouchableOpacity>
        </View>

        {isLargeScreen ? (
          <FlatList
            data={categoryVendors}
            key={'grid'}
            keyExtractor={(item) => item.userId}
            numColumns={6}
            columnWrapperStyle={styles.columnWrapper}
            ItemSeparatorComponent={() => <View style={{ width: 10 }} />}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.touchableCard}
                onPress={() => {
                  setSelectedVendor(item);
                  setModalVisible(true);
                }}
              >
                <VendorCard vendor={item} />
                {item.promoted && <Text style={styles.adLabel}>Ad</Text>}
              </TouchableOpacity>
            )}
          />
        ) : (
          <FlatList
            data={categoryVendors}
            key={'list'}
            keyExtractor={(item) => item.userId}
            horizontal
            showsHorizontalScrollIndicator={true}
            ItemSeparatorComponent={() => <View style={{ width: 10 }} />}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.touchableCard}
                onPress={() => {
                  setSelectedVendor(item);
                  setModalVisible(true);
                }}
              >
                <VendorCard vendor={item} />
                {item.promoted && <Text style={styles.adLabel}>Ad</Text>}
              </TouchableOpacity>
            )}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
        <SafeAreaView>
          <View style={styles.selectorRow}>
            <TouchableOpacity
              onPress={() => navigation.navigate('Location - Aweyy')}
              style={[styles.selectionBox, styles.selectorItem]}
            >
              <Ionicons name="location-outline" size={20} color="#333" />
              <Text style={styles.selectionText}>{location || 'Select Location'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => navigation.navigate('Category - Aweyy', { location })}
              style={[styles.selectionBox, styles.selectorItem]}
            >
              <Ionicons name="list-outline" size={20} color="#333" />
              <Text style={styles.selectionText}>
                {categories.length ? categories.join(', ') : 'Select Categories'}
              </Text>
            </TouchableOpacity>
          </View>

          {renderAdCarousel()}

          {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

          {loading ? (
            <ActivityIndicator size="large" color="#b6cc9b" />
          ) : (
            categories.map((category) => renderVendorCarousel(category))
          )}
        </SafeAreaView>
      </ScrollView>

      {selectedVendor && (
        <Modal
          visible={modalVisible}
          animationType="slide"
          onRequestClose={() => setModalVisible(false)}
        >
          <VendorProfileScreen
            vendor={selectedVendor}
            isExplorer={true}
            onClose={() => setModalVisible(false)}
          />
        </Modal>
      )}

      {Platform.OS === 'web' && (
        <View style={styles.footer}>
          <View style={styles.footerContent}>
            <Text style={styles.footerText}>© 2025 aweyy app. All rights reserved.</Text>
            <View style={styles.footerLinksContainer}>
              <TouchableOpacity onPress={() => navigation.navigate('Privacy Policy')}>
                <Text style={styles.linkText}>Privacy Policy</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Terms of Service')}>
                <Text style={styles.linkText}>Terms of Service</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
                <Text style={styles.linkText}>Contact Us</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.socialMediaIcons}>
              <Ionicons name="logo-tiktok" size={24} color="#555" />
              <TouchableOpacity onPress={() => Linking.openURL('https://www.instagram.com/aweyytoday/')}>
                <Ionicons name="logo-instagram" size={24} color="#555" style={styles.iconSpacing} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const buttonStyle = (activeTab: string, tab: string) => ({
  backgroundColor: activeTab === tab ? '#b6cc9b' : '#FFFFFF',
  paddingVertical: 12,
  paddingHorizontal: 30,
  borderRadius: 15,
  marginHorizontal: 5,
  borderWidth: 1,
  borderColor: '#b6cc9b',
});

const buttonTextStyle = (activeTab: string, tab: string) => ({
  color: activeTab === tab ? '#000000' : '#000000',
  fontWeight: '600',
  fontSize: 16,
});

const styles = StyleSheet.create({
  adLabel: {
    position: 'absolute',
    top: 5,
    left: 5,
    backgroundColor: '#fff',
    color: '#a4a4a4',
    paddingHorizontal: 5,
    paddingVertical: 2,
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  container: { flex: 1, backgroundColor: '#fff' },
  scrollContainer: { paddingBottom: 80, paddingHorizontal: 15 },
  selectorRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  selectionBox: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#b6cc9b',
    alignSelf: 'flex-start',
  },
  selectorItem: { flex: 1, marginRight: 10 },
  selectionText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    marginLeft: 5,
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  vendorSection: { marginBottom: 30, marginTop: 0 },
  vendorHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vendorTitle: {
    fontSize: 20,
    fontWeight: '700',
    color: '#4A4A4A',
    marginBottom: 15,
  },
  seeAllText: {
    fontSize: 16,
    color: '#444',
    fontWeight: '600',
    textDecorationLine: 'underline',
  },
  touchableCard: { width: ITEM_WIDTH, marginBottom: 15 },
  columnWrapper: { justifyContent: 'flex-start', gap: 10 },
  noVendorsContainer: { alignItems: 'center', justifyContent: 'center', padding: 20 },
  noVendorsText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    textAlign: 'center',
  },
  errorText: { color: 'red', textAlign: 'center', marginBottom: 10 },
  adCarouselContainer: { marginBottom: 20, justifyContent: 'center' },
  adHorizontalContainer: { alignItems: 'center', paddingHorizontal: 10 },
  adGridContainer: { justifyContent: 'space-between', paddingHorizontal: 20 },
  adColumnWrapper: { justifyContent: 'space-between', marginBottom: 15 },
  adGridCard: {
    flex: 1,
    marginHorizontal: 10,
    maxWidth: Dimensions.get('window').width / 3 - 30,
  },
  adTouchableCard: {
    width: Dimensions.get('window').width * 0.8,
    marginRight: 15,
    alignSelf: 'center',
  },
  adImageContainer: {
    width: '100%',
    height: 200,
    borderRadius: 15,
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adImage: { width: '100%', height: '100%' },
  footer: {
    backgroundColor: '#fff',
    padding: 20,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  footerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  footerText: { color: '#555', fontSize: 14, fontWeight: 'bold' },
  footerLinks: { flexDirection: 'row', justifyContent: 'space-between', width: '30%' },
  linkText: { color: '#555', fontSize: 14, fontWeight: 'bold' },
  socialMediaIcons: { flexDirection: 'row' },
  iconSpacing: { marginHorizontal: 10 },
});
