// EditVendorProfile.tsx
import React, { useState, useEffect } from 'react';
import { 
  View, 
  Text, 
  TextInput, 
  TouchableOpacity, 
  StyleSheet, 
  ScrollView, 
  ActivityIndicator, 
  Modal, 
  FlatList, 
  Image 
} from 'react-native';
import * as ImagePicker from 'expo-image-picker'; 
import { firestore, auth, storage } from '../../firebaseConfig'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { Ionicons } from '@expo/vector-icons';

export default function EditVendorProfile({ navigation }: any) {
  const [vendorName, setVendorName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [googleMapsLocation, setGoogleMapsLocation] = useState(''); // Google Maps URL field
  const [isModalVisible, setModalVisible] = useState(false); 
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null); 
  const [imageUploading, setImageUploading] = useState(false);

  const currentUser = auth.currentUser;

  // Sample locations and categories arrays
  const locations = ['Bali', 'Copenhagen', 'Chiang Mai'];
  const categories = ['Accommodation', 'Beauty', 'Health', 'Experiences', 'Food', 'Curated Shops', 'Tattoos', 'Thrift Shops'];

  useEffect(() => {
    const fetchVendorProfile = async () => {
      if (currentUser) {
        try {
          const vendorRef = doc(firestore, 'vendors', currentUser.uid);
          const vendorSnap = await getDoc(vendorRef);
          if (vendorSnap.exists()) {
            const vendorData = vendorSnap.data();
            setVendorName(vendorData.vendorName);
            setDescription(vendorData.description);
            setSelectedLocation(vendorData.location);
            setSelectedCategory(vendorData.category);
            setGoogleMapsLocation(vendorData.googleMapsLocation || '');
            setProfilePicture(vendorData.profilePicture || null);
          }
        } catch (error) {
          setErrorMessage('Error fetching vendor profile');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVendorProfile();
  }, [currentUser]);

  const handleProfileImagePicker = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      setErrorMessage('Sorry, we need camera roll permissions to make this work!');
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images, // Use the correct export here!
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.3,
    });

    if (!result.canceled) {
      uploadProfileImage(result.assets[0].uri);  
    }
  };

  const uploadProfileImage = async (uri: string) => {
    setImageUploading(true);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      // Note: Make sure the storage path is a string – wrap it in backticks correctly:
      const storageRef = ref(storage, `vendorProfilePictures/${currentUser?.uid}`); 
      await uploadBytes(storageRef, blob); 
      const downloadUrl = await getDownloadURL(storageRef); 
      setProfilePicture(downloadUrl); 
      
      if (currentUser) {
        const vendorRef = doc(firestore, 'vendors', currentUser.uid);
        await setDoc(vendorRef, { profilePicture: downloadUrl }, { merge: true });
      }
    } catch (error) {
      setErrorMessage('Error uploading profile image');
    } finally {
      setImageUploading(false);
    }
  };

  const handleSaveProfile = async () => {
    if (currentUser && selectedLocation && selectedCategory) {
      try {
        const vendorRef = doc(firestore, 'vendors', currentUser.uid);
        // Only update the fields that might change
        await updateDoc(vendorRef, {
          vendorName,
          description,
          location: selectedLocation,
          category: selectedCategory,
          googleMapsLocation, // Save Google Maps URL to Firestore
          profilePicture,
        });
        navigation.goBack();
      } catch (error) {
        setErrorMessage('Error saving vendor profile');
      }
    } else {
      setErrorMessage('Please select a location and category');
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color="#b6cc9b" />
      </View>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <View style={styles.container}>
        {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}

        <TouchableOpacity onPress={handleProfileImagePicker} style={styles.profileImageContainer}>
          {profilePicture ? (
            <Image source={{ uri: profilePicture }} style={styles.profileImage} resizeMode="contain"/>
          ) : (
            <Ionicons name="camera" size={100} color="#b6cc9b" />
          )}
        </TouchableOpacity>

        {imageUploading ? <ActivityIndicator size="small" color="#b6cc9b" /> : null}

        <TextInput
          placeholder="Vendor Name"
          style={styles.input}
          value={vendorName}
          onChangeText={setVendorName}
        />

        <TextInput
          placeholder="Description"
          style={styles.input}
          value={description}
          onChangeText={setDescription}
        />

        {/* Google Maps URL Input */}
        <TextInput
          placeholder="Google Maps URL"
          style={styles.input}
          value={googleMapsLocation}
          onChangeText={setGoogleMapsLocation}
        />

        {/* Location Selector Button */}
        <TouchableOpacity style={styles.dropdownButton} onPress={() => setModalVisible(true)}>
          <Text style={styles.dropdownButtonText}>
            {selectedLocation ? `Location: ${selectedLocation}` : 'Select Location'}
          </Text>
          <Ionicons name="chevron-down" size={24} color="#333" />
        </TouchableOpacity>

        {/* Category Selector Button */}
        <TouchableOpacity style={styles.dropdownButton} onPress={() => setCategoryModalVisible(true)}>
          <Text style={styles.dropdownButtonText}>
            {selectedCategory ? `Category: ${selectedCategory}` : 'Select Category'}
          </Text>
          <Ionicons name="chevron-down" size={24} color="#333" />
        </TouchableOpacity>

        <TouchableOpacity style={styles.saveButton} onPress={handleSaveProfile}>
          <Text style={styles.saveButtonText}>Save</Text>
        </TouchableOpacity>
      </View>

      {/* Modal for Selecting Location */}
      <Modal transparent={true} visible={isModalVisible} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>Select Location</Text>
            <FlatList
              data={locations}
              keyExtractor={(item) => item}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={styles.modalItem}
                  onPress={() => {
                    setSelectedLocation(item);
                    setModalVisible(false); 
                  }}>
                  <Text style={styles.modalItemText}>{item}</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </Modal>

      {/* Modal for Selecting Category */}
      <Modal transparent={true} visible={isCategoryModalVisible} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>Select Category</Text>
            <FlatList
              data={categories}
              keyExtractor={(item) => item}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={styles.modalItem}
                  onPress={() => {
                    setSelectedCategory(item);
                    setCategoryModalVisible(false);
                  }}>
                  <Text style={styles.modalItemText}>{item}</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    backgroundColor: '#fff',
    paddingBottom: 100,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    paddingTop: 50,
    minHeight: '100%',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  profileImageContainer: {
    alignSelf: 'center',
    marginBottom: 20,
    borderRadius: 100,
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF0F6',
    borderWidth: 2,
    borderColor: '#b6cc9b',
  },
  profileImage: {
    width: 150,
    height: 150,
    borderRadius: 100,
  },
  input: {
    height: 50,
    width: '90%',
    backgroundColor: '#fff',
    borderColor: '#b6cc9b',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 20,
    textAlign: 'center',
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    justifyContent: 'space-between',
    borderWidth: 2,
    borderColor: '#b6cc9b',
    marginBottom: 20,
    width: '90%',
  },
  dropdownButtonText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  saveButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginBottom: 20,
    width: '90%',
  },
  saveButtonText: {
    color: '#FFF',
    fontWeight: '600',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 20,
    marginHorizontal: 10,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    textAlign: 'center',
  },
  modalItem: {
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 300,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#b6cc9b',
  },
  modalItemText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
    textAlign: 'center',
  },
});
