import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export default function PrivacyPolicyScreen() {
  const navigation = useNavigation();

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Privacy Policy</Text>
        </View>

        {/* Content */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          <Text style={styles.sectionTitle}>Privacy Policy</Text>
          <Text style={styles.content}>Effective Date: 1 February 2025</Text>

          <Text style={styles.content}>
            Welcome to AWEYY! This Privacy Policy explains how we collect, use, and protect your personal information. By using our app, you agree to the terms outlined below. If you do not agree, please discontinue use of the app.
          </Text>

          <Text style={styles.subTitle}>1. Information We Collect</Text>
          <Text style={styles.content}>
            1.1 Personal Information: When you create an account, we collect information such as your name, email address, and account details.{"\n\n"}
            1.2 Usage Information: We collect data on how you interact with the app, including saved vendors, clicks, and preferences.{"\n\n"}
            1.3 Device Information: This includes IP address, device type, operating system, and app version.{"\n\n"}
            1.4 Location Data: If you allow location access, we may collect location data to enhance app functionality, such as vendor recommendations.
          </Text>

          <Text style={styles.subTitle}>2. How We Use Your Information</Text>
          <Text style={styles.content}>
            2.1 Providing Services: We use your data to operate the app, manage accounts, and facilitate vendor interactions.{"\n\n"}
            2.2 Personalization: Your data helps us tailor content, such as vendor recommendations and promotions.{"\n\n"}
            2.3 Analytics and Improvement: We use aggregated data to understand user behavior and enhance app performance.{"\n\n"}
            2.4 Communication: We send updates, newsletters, and promotional content. Users can opt out of marketing emails at any time.
          </Text>

          <Text style={styles.subTitle}>3. Sharing Your Information</Text>
          <Text style={styles.content}>
            3.1 With Vendors: Public information like reviews or engagement data may be shared with vendors.{"\n\n"}
            3.2 Service Providers: We work with third-party providers for analytics, marketing, and app functionality (e.g., Firebase, payment processors, email services).{"\n\n"}
            3.3 Compliance and Legal Requests: We may share your data if required by law or to enforce our Terms of Service.{"\n\n"}
            3.4 Advertising Partners: We may share non-personally identifiable data with advertisers to improve ad relevance.
          </Text>

          <Text style={styles.subTitle}>4. Advertising, Analytics, and Tracking</Text>
          <Text style={styles.content}>
            4.1 Advertising: We display ads labeled as "Sponsored" or "Promoted" and may use tracking technologies to measure engagement.{"\n\n"}
            4.2 Analytics: We use tools such as Firebase Analytics and Google Analytics to monitor app usage and improve user experience.{"\n\n"}
            4.3 Cookies and Tracking Technologies: We may use cookies and similar tracking technologies in the future to enhance user experience, maintain authentication sessions, and analyze app usage. Currently, AWEYY relies primarily on Firebase Authentication, which does not store login data in cookies but instead uses local storage.
          </Text>

          <Text style={styles.subTitle}>5. Your Privacy Choices</Text>
          <Text style={styles.content}>
            5.1 Access and Update: Users can update their personal information through account settings.{"\n\n"}
            5.2 Opt-Out: You can unsubscribe from marketing emails via settings.{"\n\n"}
            5.3 Data Deletion: To delete your account and associated data, contact heyy@aweyy.com. Note: Some data may be retained for legal or business reasons.
          </Text>

          <Text style={styles.subTitle}>6. Data Security & Retention</Text>
          <Text style={styles.content}>
            6.1 Security Measures: We implement industry-standard security measures to protect your data, but no system is 100% secure. Users are responsible for safeguarding their login credentials.{"\n\n"}
            6.2 Data Retention: Personal data is retained as long as your account is active. If you delete your account, most personal data will be removed within 30 days, but some information may be retained for legal or operational purposes.
          </Text>

          <Text style={styles.subTitle}>7. International Data Transfers</Text>
          <Text style={styles.content}>
            AWEYY operates globally. By using the app, you consent to the transfer of your data to servers that may be located in different countries with varying data protection laws.
          </Text>

          <Text style={styles.subTitle}>8. Children’s Privacy</Text>
          <Text style={styles.content}>
            AWEYY does not knowingly collect data from users under 13. If we discover a child under 13 has provided personal data, we will delete it immediately.
          </Text>

          <Text style={styles.subTitle}>9. Changes to This Privacy Policy</Text>
          <Text style={styles.content}>
            We may update this Privacy Policy periodically. If changes are significant, we will notify users via email or in-app notifications. Continued use of the app constitutes acceptance of the updated policy.
          </Text>

          <Text style={styles.subTitle}>10. Contact Us</Text>
          <Text style={styles.content}>
            If you have any questions or concerns about this Privacy Policy, contact us at:{"\n"}
            Email: heyy@aweyy.com
          </Text>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: { flex: 1 },
  safeArea: { flex: 1 },
  header: { flexDirection: 'row', alignItems: 'center', paddingVertical: 15, paddingHorizontal: 15 },
  backButton: { padding: 5 },
  headerTitle: { fontSize: 20, fontWeight: 'bold', color: '#333', marginLeft: 10 },
  contentContainer: { padding: 20 },
  sectionTitle: { fontSize: 18, fontWeight: 'bold', color: '#4A4A4A', marginBottom: 10 },
  subTitle: { fontSize: 16, fontWeight: '600', color: '#555', marginTop: 20, marginBottom: 10 },
  content: { fontSize: 14, lineHeight: 22, color: '#666', textAlign: 'justify' },
});
