import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';
import { auth } from '../../firebaseConfig'; // Your firebase configuration file
import {
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

export default function AccountSettingsScreen() {
  const navigation = useNavigation();
  const [currentEmail, setCurrentEmail] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // New state for the current password (used for reauthentication)
  const [currentPassword, setCurrentPassword] = useState('');

  // Fetch the signed-in user's email on mount
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentEmail(user.email);
    }
  }, []);

  // Function to reauthenticate the user using their current password
  const reauthenticate = async (currentPassword: string) => {
    const user = auth.currentUser;
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      return reauthenticateWithCredential(user, credential);
    } else {
      throw new Error('No user is signed in.');
    }
  };

  // Handler to update the user's email with reauthentication
  const handleUpdateEmail = async () => {
    if (!email) {
      Alert.alert('Error', 'Please enter your new email.');
      return;
    }
    if (!currentPassword) {
      Alert.alert('Error', 'Please enter your current password to reauthenticate.');
      return;
    }
    const user = auth.currentUser;
    if (user) {
      try {
        await reauthenticate(currentPassword);
        await updateEmail(user, email);
        Alert.alert('Success', 'Email updated successfully!');
        setCurrentEmail(email);
        setEmail('');
        setCurrentPassword('');
      } catch (error: any) {
        Alert.alert('Error', error.message);
      }
    } else {
      Alert.alert('Error', 'No user is signed in.');
    }
  };

  // Handler to change the user's password with reauthentication
  const handleChangePassword = async () => {
    if (!password || !confirmPassword) {
      Alert.alert('Error', 'Please fill out all password fields.');
      return;
    }
    if (password !== confirmPassword) {
      Alert.alert('Error', 'Passwords do not match.');
      return;
    }
    if (!currentPassword) {
      Alert.alert('Error', 'Please enter your current password to reauthenticate.');
      return;
    }
    const user = auth.currentUser;
    if (user) {
      try {
        await reauthenticate(currentPassword);
        await updatePassword(user, password);
        Alert.alert('Success', 'Password changed successfully!');
        setPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
      } catch (error: any) {
        Alert.alert('Error', error.message);
      }
    } else {
      Alert.alert('Error', 'No user is signed in.');
    }
  };

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        <KeyboardAvoidingView
          style={styles.container}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          {/* Header */}
          <View style={styles.header}>
            <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
              <Ionicons name="arrow-back" size={22} color="#333" />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Account Settings</Text>
          </View>

          {/* Current User Email */}
          {currentEmail && (
            <View style={styles.emailContainer}>
              <Text style={styles.emailText}>Signed in as:</Text>
              <Text style={styles.email}>{currentEmail}</Text>
            </View>
          )}

          {/* Form */}
          <View style={styles.form}>
            {/* Instruction for Reauthentication */}
            <View style={styles.instructionContainer}>
              <Text style={styles.instructionText}>
                To update your email or change your password, please enter your current password.
              </Text>
            </View>

            {/* Current Password Input for Reauthentication */}
            <View style={styles.inputGroup}>
              <Text style={styles.label}>Current Password</Text>
              <TextInput
                style={styles.input}
                placeholder="Enter current password"
                secureTextEntry
                value={currentPassword}
                onChangeText={setCurrentPassword}
              />
            </View>

            {/* Update Email Section */}
            <View style={styles.inputGroup}>
              <Text style={styles.label}>Update Email</Text>
              <TextInput
                style={styles.input}
                keyboardType="email-address"
                placeholder="Enter new email"
                value={email}
                onChangeText={setEmail}
              />
              <TouchableOpacity style={styles.sendButton} onPress={handleUpdateEmail}>
                <Text style={styles.sendButtonText}>Update Email</Text>
              </TouchableOpacity>
            </View>

            {/* Change Password Section */}
            <View style={styles.inputGroup}>
              <Text style={styles.label}>Change Password</Text>
              <TextInput
                style={styles.input}
                placeholder="Enter new password"
                secureTextEntry
                value={password}
                onChangeText={setPassword}
              />
              <TextInput
                style={styles.input}
                placeholder="Confirm new password"
                secureTextEntry
                value={confirmPassword}
                onChangeText={setConfirmPassword}
              />
              <TouchableOpacity style={styles.sendButton} onPress={handleChangePassword}>
                <Text style={styles.sendButtonText}>Change Password</Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  emailContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    alignItems: 'center',
  },
  emailText: {
    fontSize: 16,
    color: '#666',
  },
  email: {
    fontSize: 18,
    fontWeight: '600',
    color: '#333',
  },
  form: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 30,
  },
  instructionContainer: {
    marginBottom: 15,
    backgroundColor: '#eef',
    padding: 10,
    borderRadius: 10,
  },
  instructionText: {
    fontSize: 14,
    color: '#333',
    textAlign: 'center',
  },
  inputGroup: {
    marginBottom: 15,
  },
  label: {
    fontSize: 14,
    fontWeight: '600',
    color: '#333',
    marginBottom: 5,
  },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderColor: '#b6cc9b',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    fontSize: 14,
    color: '#666',
    marginBottom: 10,
  },
  sendButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginTop: 10,
  },
  sendButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
});
