// App.tsx
import 'react-native-gesture-handler';
import React, { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActivityIndicator, View, TouchableOpacity, Text, Image, StyleSheet, Platform, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { auth, firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import * as SplashScreen from 'expo-splash-screen';

import WelcomeScreen from './app/screens/WelcomeScreen';
import LocationSelector from './app/screens/LocationSelector';
import CategorySelector from './app/screens/CategorySelector';
import HomePage from './app/screens/HomePage';
import ExplorePage from './app/screens/ExplorePage';
import ProfilePage from './app/screens/ProfilePage';
import VendorProfileScreen from './app/screens/VendorProfileScreen';
import EditVendorProfile from './app/screens/EditVendorProfile';
import AllVendorsScreen from './app/screens/AllVendorsScreen';
import SharedListScreen from './app/screens/SharedListScreen';
import LoginScreen from './app/screens/LoginScreen';
import SignupScreen from './app/screens/SignUpScreen';
import TermsofServiceScreen from './app/screens/TermsofServiceScreen';
import PrivacyPolicyScreen from './app/screens/PrivacyPolicyScreen';
import ContactScreen from './app/screens/ContactScreen';
import RecommendationScreen from './app/screens/RecommendationsScreen';
import RequestAdScreen from './app/screens/RequestAdScreen';
import AccountSettingsScreen from './app/screens/AccountSettingsScreen';
import PrivacySettingsScreen from './app/screens/PrivacySettingsScreen';
import HelpCenterScreen from './app/screens/HelpCenterScreen';
import VendorApplyScreen from './app/screens/VendorApplyScreen';

export type RootStackParamList = {
  WelcomeScreen: undefined;
  LocationSelector: undefined;
  CategorySelector: { location: string };
  HomeTabs: { location: string; categories: string[] };
  LoginScreen: undefined;
  SignUpScreen: undefined;
  Profile: undefined;
  EditVendorProfile: undefined;
  VendorProfile: undefined;
  AllVendorsScreen: { category: string; location: string };
  SharedListScreen: undefined;
  Recommendations: undefined;
  RequestAd: undefined;
};

type TabParamList = {
  Home: { location: string; categories: string[] };
  Explore: undefined;
  Profile: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();
const BottomTab = createBottomTabNavigator<TabParamList>();

function HomeTabs({ route, navigation }: { route: { params: { location: string; categories: string[] } }; navigation: any }) {
  const { location, categories } = route.params || { location: '', categories: [] };
  const [selectedTab, setSelectedTab] = useState<'Home' | 'Explore' | 'Profile'>(Platform.OS === 'web' ? (localStorage.getItem('selectedTab') as 'Home' | 'Explore' | 'Profile') || 'Home' : 'Home');

  useEffect(() => {
    if (Platform.OS === 'web') {
      const titles = {
        Home: 'Home - Aweyy',
        Explore: 'Explore - Aweyy',
        Profile: 'Profile - Aweyy',
      };
      document.title = titles[selectedTab] || 'Aweyy';
      localStorage.setItem('selectedTab', selectedTab);
      localStorage.setItem('lastRoute', 'HomeTabs');
    }
  }, [selectedTab]);

  const isMobileWeb = Platform.OS === 'web' && Dimensions.get('window').width < 600;

  return Platform.OS === 'web' ? (
    <View style={styles.webContainer}>
      {isMobileWeb && (
        <View style={styles.logoContainerMobile}>
          <TouchableOpacity onPress={() => navigation.navigate('Home', { location, categories })}>
            <Image source={require('./assets/logo2.png')} style={styles.logoMobile} />
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.headerContainer}>
        {!isMobileWeb && (
          <View style={styles.logoContainer}>
            <TouchableOpacity onPress={() => navigation.navigate('Home', { location, categories })}>
              <Image source={require('./assets/logo2.png')} style={styles.logo} />
            </TouchableOpacity>
          </View>
        )}
        <View style={styles.tabButtons}>
          <TouchableOpacity
            style={styles.tabButton}
            onPress={() => {
              setSelectedTab('Home');
              navigation.navigate('Home', { location, categories });
            }}
          >
            <Text style={selectedTab === 'Home' ? styles.activeText : styles.inactiveText}>Home</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.tabButton}
            onPress={() => {
              setSelectedTab('Explore');
              navigation.navigate('Explore');
            }}
          >
            <Text style={selectedTab === 'Explore' ? styles.activeText : styles.inactiveText}>Explore</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            setSelectedTab('Profile');
            navigation.navigate('Profile');
          }}
          style={styles.profileButton}
        >
          <Ionicons name="person-circle-outline" size={32} color="#555" />
        </TouchableOpacity>
      </View>

      {selectedTab === 'Home' ? (
        <HomePage navigation={navigation} route={{ key: 'Home', name: 'Home', params: { location, categories } }} />
      ) : selectedTab === 'Explore' ? (
        <ExplorePage navigation={navigation} />
      ) : (
        <ProfilePage navigation={navigation} />
      )}
    </View>
  ) : (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#b6cc9b',
        tabBarInactiveTintColor: '#b0b0b0',
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: '#FFF',
          borderTopWidth: 0,
          elevation: 10,
          height: 80,
          paddingBottom: 28,
        },
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomePage}
        initialParams={{ location, categories }}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="home" size={28} color={focused ? '#b6cc9b' : '#4A4A4A'} />,
        }}
      />
      <BottomTab.Screen
        name="Explore"
        component={ExplorePage}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="planet" size={28} color={focused ? '#b6cc9b' : '#4A4A4A'} />,
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={ProfilePage}
        options={{
          tabBarIcon: ({ focused }) => <Ionicons name="person-circle-outline" size={28} color={focused ? '#b6cc9b' : '#4A4A4A'} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

export default function App() {
  const [user, setUser] = useState<null | User>(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[] | null>(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [initialRoute, setInitialRoute] = useState<'Welcome - Aweyy' | 'Home - Aweyy' | 'Location - Aweyy'>('Welcome - Aweyy');

  // Inject Meta Tags for Web
  useEffect(() => {
    if (Platform.OS === 'web') {
      const metaTags = [
        {
          name: 'apple-itunes-app',
          content: 'app-id=6740799016, app-argument=aweyy://',
        },
        {
          name: 'google-play-app',
          content: 'app-id=com.aweyy.newapp',
        },
      ];

      metaTags.forEach(tag => {
        const meta = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          meta.setAttribute(key, tag[key as keyof typeof tag] as string);
        });
        document.head.appendChild(meta);
      });
    }
  }, []);

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await Font.loadAsync({
          Ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
        });
        setFontsLoaded(true);
      } catch (error) {
        console.error('Error loading fonts:', error);
        setFontsLoaded(true);
      }
    };
    loadFonts();
  }, []);

  useEffect(() => {
    const fetchUserDataFromFirebase = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setLocation(userData.location);
          setCategories(userData.categories);

          if (userData.location && userData.categories?.length > 0) {
            setInitialRoute('Home - Aweyy');
          } else {
            setInitialRoute('Location - Aweyy');
          }
        } else {
          setInitialRoute('Location - Aweyy');
        }
      } else {
        setInitialRoute('Welcome - Aweyy');
      }
      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      if (authUser) {
        fetchUserDataFromFirebase();
      } else {
        setInitialRoute('Welcome - Aweyy');
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const prepareApp = async () => {
      try {
        await SplashScreen.preventAutoHideAsync(); // Keep splash screen visible
  
        // Ensure splash screen hides ONLY when everything is ready
        if (!loading && fontsLoaded) {
          await SplashScreen.hideAsync();
        }
      } catch (error) {
        console.error('Error hiding splash screen:', error);
      }
    };
  
    prepareApp();
  }, [loading, fontsLoaded]);
  
  if (loading || !fontsLoaded) {
    return null; // Ensure nothing is rendered during loading
  }
  

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={initialRoute} screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Welcome - Aweyy" component={WelcomeScreen} />
        <Stack.Screen name="Home - Aweyy" component={HomeTabs} initialParams={{ location, categories }} />
        <Stack.Screen name="Location - Aweyy" component={LocationSelector} />
        <Stack.Screen name="Category - Aweyy" component={CategorySelector} />
        <Stack.Screen name="Login - Aweyy" component={LoginScreen} />
        <Stack.Screen name="SignUp - Aweyy" component={SignupScreen} />
        <Stack.Screen name="Vendor Profile" component={VendorProfileScreen} />
        <Stack.Screen name="Edit Vendor Profile" component={EditVendorProfile} />
        <Stack.Screen name="All Vendors" component={AllVendorsScreen} />
        <Stack.Screen name="Shared List" component={SharedListScreen} />
        <Stack.Screen name="Terms of Service" component={TermsofServiceScreen} />
        <Stack.Screen name="Privacy Policy" component={PrivacyPolicyScreen} />
        <Stack.Screen name="Contact" component={ContactScreen} />
        <Stack.Screen name="Recommendations" component={RecommendationScreen} />
        <Stack.Screen name="RequestAd" component={RequestAdScreen} />
        <Stack.Screen name="Account Settings" component={AccountSettingsScreen} />
        <Stack.Screen name="Privacy Settings" component={PrivacySettingsScreen} />
        <Stack.Screen name="Help Center" component={HelpCenterScreen} />
        <Stack.Screen name="Vendor Apply" component={VendorApplyScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingHorizontal: 0,
    backgroundColor: '#FFFFFF',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    marginBottom: 0,
  },
  logoContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
  logo: {
    width: 220,
    height: 70,
    resizeMode: 'contain',
    marginLeft: 20,
  },
  logoContainerMobile: {
    alignItems: 'center',
    marginVertical: 10,
  },
  logoMobile: {
    width: 150,
    height: 50,
    resizeMode: 'contain',
  },
  tabButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 2,
  },
  tabButton: {
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginHorizontal: 10,
  },
  activeText: {
    color: '#555',
    fontSize: 18,
    fontWeight: '800',
  },
  inactiveText: {
    color: '#555',
    fontSize: 18,
    fontWeight: '500',
  },
  profileButton: {
    flex: 1,
    alignItems: 'flex-end',
    marginRight: 20,
  },
});
