import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  SafeAreaView,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { RootStackParamList } from '../../App';
import { auth, firestore } from '../../firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import { Platform } from 'react-native';

type CategorySelectorProps = {
  navigation: StackNavigationProp<RootStackParamList, 'CategorySelector'>;
  route: RouteProp<RootStackParamList, 'CategorySelector'>;
};

export default function CategorySelector({ navigation, route }: CategorySelectorProps) {
  const { location } = route.params;
  const categories = ['Accommodation', 'Beauty', 'Health', 'Experiences', 'Food', 'Curated Shops', 'Tattoos', 'Thrift Shops'];
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggleCategory = (category: string) => {
    setSelectedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
  };

  const submitCategories = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid); 
      await updateDoc(userRef, { categories: selectedCategories, location });
    }
  
    // Navigate back to 'Home' screen as usual
    navigation.navigate('Home - Aweyy', { location, categories: selectedCategories });
  
    // For web, force a reload on the 'Home' page
    if (Platform.OS === 'web') {
      setTimeout(() => window.location.reload(), 200);  // Delay to ensure navigation is completed
    }
  };
  
  

  return (
    <View style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent={true} />
      <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
        <SafeAreaView style={styles.safeArea}>
          <View style={styles.topSpacing} />
          <Text style={styles.title}>Pick Your Interests</Text>

          <View style={styles.contentContainer}>
            {categories.map((category) => (
              <TouchableOpacity
                key={category}
                style={[
                  styles.categoryCard,
                  selectedCategories.includes(category) && styles.categorySelected,
                ]}
                onPress={() => toggleCategory(category)}
              >
                <Text style={styles.categoryText}>{category}</Text>
                {selectedCategories.includes(category) && (
                  <Ionicons name="checkmark-circle" size={24} color="#fff" />
                )}
              </TouchableOpacity>
            ))}

            <TouchableOpacity
              style={styles.submitButton}
              onPress={submitCategories}
            >
              <Text style={styles.submitButtonText}>Submit</Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    paddingHorizontal: 20,
  },
  safeArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topSpacing: {
    height: 30, 
  },
  title: {
    fontSize: 32,
    fontWeight: '800',
    color: '#4A4A4A',
    marginBottom: 30,
    textAlign: 'center',
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
    width: 300,
    borderWidth: 2,
    borderColor: '#b6cc9b',
  },
  categorySelected: {
    backgroundColor: '#b6cc9b',
    borderColor: '#b6cc9b',
  },
  categoryText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
  submitButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 15,
    marginTop: 20,
  },
  submitButtonText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '600',
  },
});
