// ExplorePage.tsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  ActivityIndicator,
  SafeAreaView,
  Modal,
  FlatList,
  Platform,
} from 'react-native';
import { firestore, auth } from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  startAfter,
  limit,
  orderBy,
} from 'firebase/firestore';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import VendorProfileScreen from './VendorProfileScreen';
import VendorCard from './VendorCard';
import { Ionicons } from '@expo/vector-icons';
import { PinchGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

// Utility: currency symbol for location
const getCurrencySymbolForLocation = (location: string | null): string => {
  switch (location) {
    case 'Bali': return 'IDR';
    case 'Bangkok': return 'THB';
    case 'Chiang Mai': return 'THB';
    case 'Copenhagen': return 'DKK';
    case 'Florence': return 'EUR';
    case 'London': return 'GBP';
    case 'Nice': return 'EUR';
    case 'New York': return 'USD';
    case 'Paris': return 'EUR';
    case 'Tokyo': return 'JPY';
    default: return 'USD';
  }
};

// Helper to shuffle an array
const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Type for a post (from the new posts collection)
interface PostData {
  image: string;
  caption: string;
  price: string;
  aspectRatio: number;
  timestamp: number;
  vendorId: string;
  vendorName: string;
  // Optionally, you could include location and category if needed
  location?: string;
  category?: string;
}

export default function ExplorePage() {
  // State hooks for Discover and Favorites tabs
  const [posts, setPosts] = useState<PostData[]>([]);
  const [favoritePosts, setFavoritePosts] = useState<PostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'discover' | 'favorites'>('discover');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [vendorModalVisible, setVendorModalVisible] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [favoriteVendors, setFavoriteVendors] = useState<any[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [lastVisiblePost, setLastVisiblePost] = useState<any>(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortOption, setSortOption] = useState<'popular' | 'newest' | 'adventurous'>('popular');

  const currentUser = auth.currentUser;
  const navigation = useNavigation();
  const SCREEN_WIDTH = Dimensions.get('window').width;
  const isMobileWeb = Platform.OS === 'web' && SCREEN_WIDTH <= 600;

  // Reanimated hook for pinch zoom
  const scale = useSharedValue(1);
  const pinchGestureHandler = useAnimatedGestureHandler({
    onActive: (event) => {
      scale.value = event.scale;
    },
    onEnd: () => {
      scale.value = withSpring(1, { damping: 5 });
    },
  });
  const animatedImageStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  // Fetch user preferences: location, categories, favorites
  const fetchUserPreferences = async (): Promise<void> => {
    if (currentUser) {
      try {
        const userRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setSelectedLocation(userData.location || null);
          setSelectedCategories(userData.categories || []);
          setFavorites(userData.favorites || []);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    }
  };

  // Fetch posts for Discover tab from the "posts" collection
  const fetchVendorPosts = useCallback(async (loadMore = false) => {
    if (!selectedLocation || selectedCategories.length === 0 || (loadMore && loadingMore))
      return;
    
    setLoadingMore(loadMore);
    if (!loadMore) {
      setLoading(true);
    }
    
    try {
      const postsRef = collection(firestore, 'posts');
      let postsQuery = query(
        postsRef,
        where('location', '==', selectedLocation),
        where('category', 'in', selectedCategories),
        orderBy('timestamp', 'desc'),
        limit(10)
      );
      if (loadMore && lastVisiblePost) {
        postsQuery = query(postsQuery, startAfter(lastVisiblePost), limit(10));
      }
      const querySnapshot = await getDocs(postsQuery);
      
      if (querySnapshot.empty) {
        setPosts([]);
        setLoading(false);
        setLoadingMore(false);
        return;
      }
      
      const newPosts: PostData[] = [];
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      
      querySnapshot.forEach((docSnap) => {
        newPosts.push(docSnap.data() as PostData);
      });
      
      
      if (loadMore) {
        setPosts((prevPosts) => [...prevPosts, ...shuffleArray(newPosts)]);
      } else {
        setPosts(shuffleArray(newPosts));
      }
      
      setLastVisiblePost(lastDoc || lastVisiblePost);
    } catch (error) {
      console.error('❌ Error fetching posts:', error);
    }
    
    setLoading(false);
    setLoadingMore(false);
  }, [selectedLocation, selectedCategories, lastVisiblePost, loadingMore]);

  // Refresh handler for pull-to-refresh
  const onRefresh = async (): Promise<void> => {
    setRefreshing(true);
    await Promise.all([fetchUserPreferences(), fetchVendorPosts()]);
    setRefreshing(false);
  };

  useEffect(() => {
    fetchUserPreferences();
  }, [currentUser]);

  useEffect(() => {
    if (selectedLocation && selectedCategories.length > 0) {
      fetchVendorPosts();
    }
  }, [selectedLocation, selectedCategories]);

  useFocusEffect(
    useCallback(() => {
      fetchUserPreferences();
    }, [])
  );

  // Favorites section
  // (A) Fetch favorited vendors (for header display)
  const fetchFavoriteVendors = async () => {
    const vendorData: any[] = [];
    for (const vendorId of favorites) {
      try {
        const vendorRef = doc(firestore, 'vendors', vendorId);
        const vendorSnap = await getDoc(vendorRef);
        if (vendorSnap.exists()) {
          const vendor = vendorSnap.data();
          if (vendor.location === selectedLocation) {
            vendorData.push({ ...vendor, userId: vendorId });
          }
        }
      } catch (error) {
        console.error('Error fetching favorite vendor:', error);
      }
    }
    setFavoriteVendors(vendorData);
  };

  // (B) Fetch posts from favorited vendors (from the "posts" collection)
  const fetchFavoritePosts = async () => {
    if (!favorites || favorites.length === 0) {
      setFavoritePosts([]);
      return;
    }
    try {
      const postsRef = collection(firestore, 'posts');
      // Use Firestore "in" operator (max 10 items). Adjust if needed.
      const postsQuery = query(
        postsRef,
        where('vendorId', 'in', favorites),
        where('location', '==', selectedLocation),
        orderBy('timestamp', 'desc')
      );
      const querySnapshot = await getDocs(postsQuery);
      const favPosts: PostData[] = [];
      querySnapshot.forEach((docSnap) => {
        favPosts.push(docSnap.data() as PostData);
      });
      setFavoritePosts(favPosts);
    } catch (error) {
      console.error('Error fetching favorite posts:', error);
    }
  };

  // When the Favorites tab is selected, fetch both vendors and posts
  const handleTabChange = async (tab: 'discover' | 'favorites') => {
    setActiveTab(tab);
    if (tab === 'favorites') {
      await fetchFavoriteVendors();
      await fetchFavoritePosts();
    }
  };

  const getGridColumns = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth > 768 ? 4 : 2;
  };

  // Memoized render item for Discover tab
  const renderPostItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={[styles.postCard, { width: `${100 / getGridColumns()}%`, marginHorizontal: 2 }]}
        onPress={() => {
          setSelectedPost(item);
          setImageModalVisible(true);
        }}
      >
        <Image
          source={{ uri: item.image }}
          style={[
            styles.postImage,
            {
              width: '100%',
              height: Math.min(
                Dimensions.get('window').width / (item.aspectRatio || 1),
                300
              ),
            },
          ]}
          resizeMode="cover"
        />
        <View style={styles.postInfo}>
          {item.vendorName && <Text style={styles.vendorName}>{item.vendorName}</Text>}
          {item.price && (
            <Text style={styles.postPrice}>
              {getCurrencySymbolForLocation(selectedLocation)} {item.price}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    ),
    [getGridColumns, selectedLocation]
  );

  const closeImageModal = () => {
    setImageModalVisible(false);
    setSelectedPost(null);
  };

  const handleVisitVendor = async (vendorId: string) => {
    try {
      const vendorRef = doc(firestore, 'vendors', vendorId);
      const vendorSnap = await getDoc(vendorRef);
      if (vendorSnap.exists()) {
        // Add the document ID to the vendor data.
        const vendorData = { ...vendorSnap.data(), vendorId: vendorSnap.id, userId: vendorSnap.id };
        setSelectedVendor(vendorData);
        setImageModalVisible(false);
        setVendorModalVisible(true);
      } else {
        console.error('Vendor not found');
      }
    } catch (error) {
      console.error('Error fetching vendor details:', error);
    }
  };
  

  const closeVendorModal = () => {
    setVendorModalVisible(false);
    setSelectedVendor(null);
  };

  // Render header for Favorites tab: show favorite vendors as horizontal scroll (VendorCards)
  const renderFavoritesHeader = () => (
    <View>
      <View style={styles.explanationBox}>
        <Text style={styles.explanationText}>
          To add a place to your favorites, tap the{' '}
          <Ionicons name="heart-outline" size={18} color="#555" /> button in the top right corner on their profile.
        </Text>
      </View>
      <View style={styles.vendorSection}>
        <Text style={styles.sectionTitle}>Your Favorite Vendors</Text>
        <FlatList
          data={favoriteVendors}
          keyExtractor={(item) => item.userId}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.vendorCardContainer}
              onPress={() => handleVisitVendor(item.userId)}
            >
              <VendorCard vendor={item} />
            </TouchableOpacity>
          )}
        />
      </View>
      <View style={styles.sectionTitleContainer}>
        <Text style={styles.sectionTitle}>Posts from Your Favorite Vendors</Text>
      </View>
    </View>
  );

  // Render item for Favorites tab posts
  const renderFavoritePostItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={[styles.postCard, { width: `${100 / getGridColumns()}%`, marginHorizontal: 2 }]}
        onPress={() => {
          setSelectedPost(item);
          setImageModalVisible(true);
        }}
      >
        <Image
          source={{ uri: item.image }}
          style={[
            styles.postImage,
            {
              width: '100%',
              height: Math.min(
                Dimensions.get('window').width / (item.aspectRatio || 1),
                300
              ),
            },
          ]}
          resizeMode="cover"
        />
      </TouchableOpacity>
    ),
    [getGridColumns]
  );

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#b6cc9b" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      {/* Tab Buttons */}
      <View style={styles.buttonsContainer}>
        <TouchableOpacity style={buttonStyle(activeTab, 'discover')} onPress={() => handleTabChange('discover')}>
          <Text style={buttonTextStyle(activeTab, 'discover')}>Discover</Text>
        </TouchableOpacity>
        <TouchableOpacity style={buttonStyle(activeTab, 'favorites')} onPress={() => handleTabChange('favorites')}>
          <Text style={buttonTextStyle(activeTab, 'favorites')}>Favorites</Text>
        </TouchableOpacity>
      </View>

      {/* Discover Tab */}
      {activeTab === 'discover' && (
        <FlatList
          data={posts}
          keyExtractor={(item, index) => item.vendorId + index.toString()}
          numColumns={getGridColumns()}
          contentContainerStyle={styles.flatListContainer}
          onEndReached={() => {
            if (!loadingMore && posts.length >= 10) {
              fetchVendorPosts(true);
            }
          }}
          onEndReachedThreshold={0.3}
          refreshing={refreshing}
          onRefresh={onRefresh}
          initialNumToRender={12}
          maxToRenderPerBatch={6}
          windowSize={10}
          ListFooterComponent={loadingMore ? <ActivityIndicator size="small" color="#b6cc9b" /> : null}
          ListEmptyComponent={
            <View style={styles.emptyContainer}>
              <Text style={styles.emptyText}>
                No content available in this location or category. Try choosing another location or category.
              </Text>
            </View>
          }
          renderItem={renderPostItem}
        />
      )}

      {/* Favorites Tab */}
      {activeTab === 'favorites' && (
        <FlatList
          data={favoritePosts}
          keyExtractor={(item, index) => item.vendorId + index.toString()}
          numColumns={getGridColumns()}
          contentContainerStyle={styles.flatListContainer}
          ListHeaderComponent={renderFavoritesHeader}
          ListEmptyComponent={<Text style={styles.noFavoritesText}>You have no favorites in this location.</Text>}
          renderItem={renderFavoritePostItem}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      )}

      {/* Full-Screen Image Modal */}
      <Modal visible={imageModalVisible} transparent animationType="slide" onRequestClose={closeImageModal}>
        {selectedPost && (
          <View style={styles.fullModalOverlay}>
            <Animated.Image
              source={{ uri: selectedPost.image }}
              style={[styles.fullImage, animatedImageStyle]}
              resizeMode="contain"
            />
            <TouchableOpacity style={styles.modalButton} onPress={() => handleVisitVendor(selectedPost.vendorId)}>
              <Text style={styles.buttonText}>Visit Vendor</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalCloseButton} onPress={closeImageModal}>
              <Ionicons name="close" size={30} color="#fff" />
            </TouchableOpacity>
          </View>
        )}
      </Modal>

      {/* Vendor Profile Modal */}
      <Modal visible={vendorModalVisible} animationType="slide" onRequestClose={closeVendorModal}>
        {selectedVendor && (
          <VendorProfileScreen
            vendor={selectedVendor}
            isExplorer={true}
            onClose={closeVendorModal}
          />
        )}
      </Modal>
    </SafeAreaView>
  );
}

// Helper functions for tab button styles
const buttonStyle = (activeTab: string, tab: string) => ({
  backgroundColor: activeTab === tab ? '#b6cc9b' : '#FFFFFF',
  paddingVertical: 12,
  paddingHorizontal: 30,
  borderRadius: 15,
  marginHorizontal: 5,
  borderWidth: 1,
  borderColor: '#b6cc9b',
});

const buttonTextStyle = (activeTab: string, tab: string) => ({
  color: activeTab === tab ? '#000000' : '#000000',
  fontWeight: '600',
  fontSize: 16,
});

// Styles
const styles = StyleSheet.create({
  safeArea: { flex: 1, backgroundColor: '#fff' },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  buttonsContainer: { flexDirection: 'row', justifyContent: 'center', marginBottom: 20 },
  flatListContainer: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    marginRight: 7,
    justifyContent: 'center',
  },
  explanationBox: {
    backgroundColor: '#fff',
    padding: 10,
    marginHorizontal: 15,
    marginBottom: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#b6cc9b',
  },
  explanationText: { fontSize: 16, color: '#333', textAlign: 'center' },
  sectionTitle: { fontSize: 18, fontWeight: 'bold', color: '#4A4A4A', paddingVertical: 10, marginLeft: 15 },
  noFavoritesText: { color: '#333', fontSize: 16, padding: 10, textAlign: 'center' },
  vendorCardContainer: { marginRight: 0, marginLeft: 15, marginBottom: 20 },
  postCard: { borderRadius: 10, overflow: 'hidden', marginBottom: 10, elevation: 3 },
  postImage: { width: '100%', borderRadius: 10 },
  postInfo: { padding: 5, alignItems: 'center' },
  vendorName: { fontSize: 14, color: '#333', textAlign: 'center', fontWeight: 'bold' },
  postPrice: { fontSize: 16, color: '#555', textAlign: 'center', fontWeight: 'bold' },
  fullModalOverlay: { flex: 1, backgroundColor: '#000', justifyContent: 'center', alignItems: 'center' },
  fullImage: { width: '100%', height: '100%' },
  modalButton: { position: 'absolute', bottom: 50, backgroundColor: '#b6cc9b', paddingVertical: 12, paddingHorizontal: 20, borderRadius: 15 },
  modalCloseButton: { position: 'absolute', top: 40, right: 20 },
  topCloseButton: { position: 'absolute', top: 40, left: 20, zIndex: 10 },
  mapsButton: { backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingVertical: 12, borderRadius: 15, borderWidth: 1, borderColor: '#b6cc9b', marginVertical: 10, width: '60%', marginBottom: 25 },
  mapsButtonText: { color: '#333', fontWeight: '500', fontSize: 16, marginRight: 10 },
  friendButton: { backgroundColor: '#FFF0F6', padding: 10, borderRadius: 15, marginBottom: 5 },
  selectedFriendButton: { borderWidth: 2, borderColor: '#b6cc9b' },
  friendButtonText: { color: '#4A4A4A', fontWeight: '600' },
  orText: { marginVertical: 10, textAlign: 'center', color: '#666' },
  vendorSection: { marginBottom: 15, paddingHorizontal: 15 },
});
