// VendorCard.tsx
import React from 'react';
import { View, Text, StyleSheet, Image, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

type VendorCardProps = {
  vendor: {
    vendorName: string;
    location: string;
    profilePicture?: string;
    userId: string;
  };
};

const VendorCard = ({ vendor }: VendorCardProps) => {
  return (
    <View style={styles.cardContainer}>
      {vendor.profilePicture ? (
        <Image
          source={{ uri: vendor.profilePicture }}
          style={styles.profileImage}
          resizeMode="contain"
        />
      ) : (
        <Ionicons
          name="person-circle-outline"
          size={Platform.OS === 'web' ? 120 : 80}
          color="#b6cc9b"
        />
      )}
      <Text style={styles.vendorName}>{vendor.vendorName}</Text>
      <View style={styles.infoRow}>
        <Ionicons
          name="location-outline"
          size={Platform.OS === 'web' ? 24 : 20}
          color="#4A4A4A"
        />
        <Text style={styles.infoText}>{vendor.location}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: '#FFF',
    padding: Platform.OS === 'web' ? 20 : 15,
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 0.5,
    borderColor: '#b6cc9b',
    width: Platform.OS === 'web' ? 320 : 140,
    height: Platform.OS === 'web' ? 260 : 200, // ✅ Set fixed height
    justifyContent: 'space-between', // ✅ Ensures uniform spacing
  },
  profileImage: {
    width: Platform.OS === 'web' ? 160 : 80,
    height: Platform.OS === 'web' ? 160 : 80,
    borderRadius: Platform.OS === 'web' ? 80 : 40,
    marginBottom: 10,
  },
  vendorName: {
    fontSize: Platform.OS === 'web' ? 24 : 16,
    fontWeight: '600',
    color: '#4A4A4A',
    textAlign: 'center',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  infoText: {
    fontSize: Platform.OS === 'web' ? 18 : 14,
    color: '#4A4A4A',
    marginLeft: 5,
  },
});

export default React.memo(VendorCard);
